import {Container} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
        width: {
            maxWidth: 1440,
            padding: '0 1.25rem',
            '@media (max-width: 1280px)': {
                padding: 0,
            },
        },
    })
);

interface Props {
    children: any;
    className?: string;
    ebook?: boolean;
    isCommunity?: boolean;
};

export default function KirdContainer(props: Props) {
    const classes = useStyles();
    const {children, className, ebook = false, isCommunity} = props;
    return (
        <Container className={clsx(classes.width, className)} style={ebook ? {position:"relative"} : isCommunity ? {maxWidth: "1440px"} : {}}>
            {children}
        </Container>
    );
};

